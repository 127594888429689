import moment from 'moment'
moment.locale('es')
export default {
    // Format number to ARS currency
    currency: (value) => {
        if (!value) return ''
        var formatter = new Intl.NumberFormat('es-AR', {
            style: 'currency',
            currency: 'ARS',
            minimumFractionDigits: 2
        })
        return formatter.format(value)
    },
    shortDate: (value) => {
        return moment(value).format('DD/MM/YYYY ')
    },
    friendlyDate: (value) => {
        return moment(value).format('LL')
    },
    longDateTime: (value) => {
        return moment(value).format('DD/MM/YYYY HH:mm:ss')
    },
    // Format number to non decimal percentage
    percentage: (value, decimals) => {
        if (!value) value = 0
        if (!decimals) decimals = 0

        return Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals) + '%'
    }
}
