<template>
    <div :style="'min-height:' + mh + 'px'">
        <div class="sk-fading-circle sk-primary mx-auto align-middle">
            <div class="sk-circle1 sk-circle"></div>
            <div class="sk-circle2 sk-circle"></div>
            <div class="sk-circle3 sk-circle"></div>
            <div class="sk-circle4 sk-circle"></div>
            <div class="sk-circle5 sk-circle"></div>
            <div class="sk-circle6 sk-circle"></div>
            <div class="sk-circle7 sk-circle"></div>
            <div class="sk-circle8 sk-circle"></div>
            <div class="sk-circle9 sk-circle"></div>
            <div class="sk-circle10 sk-circle"></div>
            <div class="sk-circle11 sk-circle"></div>
            <div class="sk-circle12 sk-circle"></div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'card-spinner-component',

    props: [
        'min_height'
    ],

    data () {
        return {
            mh: this.min_height || 116
        }
    }
}
</script>
