// Core
import Vue from 'vue'
import App from './App'
import router from './router'
import { store } from './store/store'
import axiosInstance from 'axios'

// Helpers
import _ from 'lodash'

// Misc
import globals from './globals'
import Popper from 'popper.js'

// Filters
import numericFilters from './filters/numerics'
import filesFilters from './filters/files'

// Components
import BlockUI from 'vue-blockui'
import BootstrapVue from 'bootstrap-vue'
import LaddaBtn from '@/vendor/libs/ladda/Ladda'
import Multiselect from 'vue-multiselect'
import { ServerTable, ClientTable } from 'vue-tables-2'
import NProgress from 'vue-nprogress'
import CardSpinner from '@/components/partials/CardSpinner'

import { ValidationProvider, ValidationObserver, extend, localize } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import es from 'vee-validate/dist/locale/es.json'

import { VueMasonryPlugin } from 'vue-masonry'
import VueImg from 'v-img'

Vue.use(VueMasonryPlugin)
Vue.use(VueImg, {
    altAsTitle: true
})
// Required to enable animations on dropdowns/tooltips/popovers
Popper.Defaults.modifiers.computeStyle.gpuAcceleration = false
Vue.config.productionTip = false

const axios = axiosInstance.create({
    baseURL: process.env.VUE_APP_API_URL,
    headers: {
        common: {
            'X-Requested-With': 'XMLHttpRequest'
        }
    }
})
window.axios = axios

Vue.axios = axios
Vue.router = router

Vue.use(require('@websanova/vue-auth'), {
    // axios interceptors
    auth: {
        request: function (req, token) {
            // Set Bearer token in header
            this.options.http._setHeaders.call(this, req, { Authorization: 'Bearer ' + token })

            // Set extra data in headers in case of refresh action
            if (req.url === this.options.refreshData.url) {
                this.options.http._setHeaders.call(this, req, { Refresh: this.token('refresh_token') })
                this.options.http._setHeaders.call(this, req, { Expiration: this.token('expires_at') })
            }
        },
        response: function (res) {
            const resData = res.data || {}

            // If new refresh token then set data
            if (resData.refresh_token && resData.expires_at) {
                this.token('refresh_token', resData.refresh_token)
                this.token('expires_at', resData.expires_at)
            }

            // If version provided then update localStorage
            if (resData.version) {
                localStorage.setItem('version', resData.version)
            }

            // If version mismatch, then force App refresh
            let version = this.options.http._getHeaders.call(this, res).version

            if (version && !localStorage.getItem('version')) {
                this.token('version', version)
            } else if (version && localStorage.getItem('version') && localStorage.getItem('version') !== version) {
                this.token('version', version)
                location.reload()
            }

            // If token provided from login, then return it
            if (resData.access_token) {
                return resData.access_token
            }
        }
    },
    http: require('@websanova/vue-auth/drivers/http/axios.1.x.js'),
    router: require('@websanova/vue-auth/drivers/router/vue-router.2.x.js'),

    tokenStore: ['localStorage', 'cookie'],
    tokenDefaultName: 'token',
    rolesVar: 'can',

    loginData: {
        url: '/login',
        method: 'POST',
        redirect: '/dashboard',
        fetchUser: true
    },

    logoutData: {
        url: '/logout',
        method: 'POST',
        redirect: '/',
        makeRequest: false
    },

    refreshData: {
        url: '/refresh',
        method: 'POST',
        enabled: true,
        interval: 15
    },

    fetchData: {
        url: '/user',
        method: 'GET',
        enabled: true
    },

    authRedirect: { name: 'login' },
    forbiddenRedirect: { name: '401' },
    notFoundRedirect: { name: 'dashboard' }, // Works when auth:false in route

    parseUserData: function (data) {
        return data
    }
})

Vue.auth.perms = function (perm) {
    return this.options.check.call(this, perm, 'can')
}

localize('es', es)
for (let rule in rules) { extend(rule, rules[rule]) }

Vue.use(NProgress)
Vue.use(BootstrapVue)

let vueTablesDefaultOptions = {
    filterable: false,
    perPage: 10,
    perPageValues: [10, 25, 50, 100],
    pagination: {
        chunk: 5,
        edge: true
    },
    texts: {
        count: 'Mostrando {from} a {to} de {count} registros|{count} registros|Un registro',
        first: 'Primero',
        last: 'Último',
        filter: 'Buscar: ',
        filterPlaceholder: 'Términos a buscar',
        limit: 'Registros: ',
        page: 'Página: ',
        noResults: 'No hay resultados',
        filterBy: 'Filtrar por {column}',
        loading: 'Cargando...',
        defaultOption: 'Seleccionar {column}',
        columns: 'Columnas'
    }
}
Vue.use(ServerTable, vueTablesDefaultOptions, false, 'bootstrap4')
Vue.use(ClientTable, {}, false, 'bootstrap4')

Vue.component('Multiselect', Multiselect)
Vue.component('LaddaBtn', LaddaBtn)
Vue.component('card-spinner', CardSpinner)

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

Vue.use(BlockUI)

// Global RTL flag
Vue.mixin({
    data: globals
})

// Filters
Object.keys(numericFilters).forEach(key => Vue.filter(key, numericFilters[key]))
Object.keys(filesFilters).forEach(key => Vue.filter(key, filesFilters[key]))

// Prototype consts
Vue.prototype.axios = axios
Vue.prototype.$eventHub = new Vue()
Vue.prototype._ = _

const nprogress = new NProgress()

new Vue({
    router,
    nprogress,
    store,
    render: h => h(App)
}).$mount('#app')
