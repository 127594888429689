<template>
    <b-navbar toggleable="lg" :variant="getLayoutNavbarBg()" class="layout-navbar align-items-lg-center container-p-x">

        <!-- Brand -->
        <b-navbar-brand to="/">
            <img src="/images/hds.png" width="150" alt="">
        </b-navbar-brand>

        <!-- Sidenav toggle -->
        <b-navbar-nav class="align-items-lg-center mr-auto mr-lg-4" v-if="sidenavToggle">
            <a class="nav-item nav-link px-0 ml-2 ml-lg-0" href="javascript:void(0)" @click="toggleSidenav">
                <!-- <i class="ion ion-md-menu text-large align-middle" /> -->
            </a>
        </b-navbar-nav>

        <!-- Navbar toggle -->
        <b-navbar-toggle target="app-layout-navbar"></b-navbar-toggle>

        <b-collapse is-nav id="app-layout-navbar">
            <router-link tag="a" :to="{name:'dashboard'}">HISTORIAL DE PACIENTES Y PROCEDIMIENTOS</router-link>
            <!-- Divider -->
            <b-navbar-nav class="align-items-lg-center ml-auto">

                <!--navbar-notifications-component v-if="notifications"></navbar-notifications-component-->

                <!-- Divider -->
                <div class="nav-item d-none d-lg-block text-big font-weight-light line-height-1 opacity-25 mr-3 ml-1">|</div>

                <b-nav-item-dropdown :right="true" class="demo-navbar-user" v-if="$auth.check()">
                    <template slot="button-content">
                        <span class="d-inline-flex flex-lg-row-reverse align-items-center align-middle">
                            <span class="px-1 mr-lg-2 ml-2 ml-lg-0">{{ $auth.user().name }}</span>
                        </span>
                    </template>

                    <router-link tag="b-dd-item" :to="{name:'account-settings', params: { id: $auth.user().id } }"><i class="ion ion-md-settings text-light"></i> &nbsp; Perfil</router-link>

                    <b-dd-divider />

                    <router-link tag="b-dd-item" :to="{name:'logout'}"><i class="ion ion-ios-log-out text-danger"></i> &nbsp; Cerrar Sesión</router-link>

                </b-nav-item-dropdown>
            </b-navbar-nav>

        </b-collapse>

    </b-navbar>
</template>

<script>
export default {
    name: 'app-layout-navbar',

    props: {
        sidenavToggle: {
            type: Boolean,
            default: true
        }
    },

    data () {
        return {
            app_title: process.env.VUE_APP_TITLE,
            env_url: process.env.VUE_APP_PRODUCTION_URL
        }
    },

    methods: {
        toggleSidenav () {
            this.layoutHelpers.toggleCollapsed()
        },

        getLayoutNavbarBg () {
            return this.layoutNavbarBg
        }
    }
}
</script>
