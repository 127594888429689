<template>
    <nav class="layout-footer footer" :class="getLayoutFooterBg()">
        <div class="container-fluid container-p-x">
            <span class="small text-muted float-right my-1">Versión {{ version }}</span>
        </div>
    </nav>
</template>

<script>
export default {
    name: 'app-layout-footer',

    data () {
        return {
            version: process.env.VUE_APP_VERSION
        }
    },

    methods: {
        getLayoutFooterBg () {
            return `bg-${this.layoutFooterBg}`
        }
    }
}
</script>
