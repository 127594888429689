import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export const store = new Vuex.Store({

    modules: {
    },

    state: {
    },

    getters: {
    },

    mutations: {
    },

    actions: {
    }
})
